import {CudaReactLayout} from "@cuda-react/core";
import SwapTenantIcon from "./components/SwapTenantOption/SwapTenantIcon";
import SwapTenantOption from "./components/SwapTenantOption/SwapTenantOption";
import CustomLogoutMenu from "./components/CustomLogoutMenu";
import CustomUnauthorizedPage from "./components/CustomUnauthorizedPage";
import Footer from "./components/Footer";
import {Route} from "react-router-dom";
import SubscriptionPage from "./components/subscription/SubscriptionPage";
import ExpiredPage from "./components/subscription/ExpiredPage";
import DeprecatedPage from "./components/subscription/DeprecatedPage";
import * as React from "react";
import CgwanModule from "@stratos/cgwan-ui";
import {getNavigationRoutes} from "./routing";

import WelcomePage from "./components/subscription/WelcomePage";

const AppContent = () => {
    const navigationEntries = getNavigationRoutes();

    return (
        <CudaReactLayout
            title="stratos.title"
            additionalNavbarOptions={[{
                name: "stratos.accounts.tenants",
                icon: <SwapTenantIcon/>,
                menu: <SwapTenantOption/>
            }]}
            customLogoutMenu={
                <CustomLogoutMenu/>
            }
            customUnauthorizedPage={CustomUnauthorizedPage}
            footerItems={<Footer/>}
            fullPageRoutes={[
                <Route
                    key="welcome"
                    exact
                    path="/welcome"
                    component={WelcomePage}
                />,
                <Route
                    key="post_purchase"
                    exact
                    path="/post_purchase"
                    component={SubscriptionPage}
                />,
                <Route
                    key="expired"
                    exact
                    path="/expired"
                    component={ExpiredPage}
                />,
                <Route
                    key="deprecated"
                    exact
                    path="/deprecated"
                    component={DeprecatedPage}
                />
            ]}
            navigation={navigationEntries}
            requiresAuthentication
        >
            <Route
                path="/"
                component={CgwanModule}
            />
        </CudaReactLayout>
    );
};

export default AppContent;