import React, {useState} from "react";
import {Button, CircularProgress, Typography} from "@cuda-networks/bds-core";
import {useTranslation} from "react-i18next";
import {Theme} from "@mui/material";
import {CudaTheme} from "@cuda-react/theme";
import {createStyles, makeStyles} from "@mui/styles";
import {
    CrudTypes,
    InputLabel,
    Select,
    useAccounts,
    useCrudFetch,
    useCrudProps,
    useGlobalParam,
    useMixpanel
} from "@cuda-react/core";
import WholePageLayout from "./WholePageLayout";
import apiResources from "../../mainApiResources";
import {get} from "lodash";
import {useHistory} from "react-router-dom";

export const styles = (theme: Theme & typeof CudaTheme) => createStyles({
    messageCenter: {
        textAlign: "center",
        paddingBottom: 8
    },
    terms: {
        textAlign: "center",
        overflowY: "scroll",
        maxHeight: 400,
        marginBottom: -theme.spacing(2),
        border: "solid 1px",
        borderColor: theme.palette.divider
    },
    inputRoot: {
        width: "auto"
    },
    inputHelp: {
        width: 0,
        margin: 0
    },
    inputLabel: {

    }
});

const useStyles = makeStyles(styles);

type TrialStage = {
    title: string,
    message?: string,
    list?: "message" | "terms",
    demo?: boolean,
    selectAccount?: boolean,
    showAccount?: boolean,
    cancel?: boolean,
    accept?: boolean,
    startTrial?: boolean,
    continue?: boolean
}

type TrialContents = {welcome: TrialStage, start: TrialStage, terms: TrialStage};
type TrialProgressStates = keyof TrialContents;
const trialContents: TrialContents = {
    welcome: {
        title: "stratos.welcome.title",
        message: "stratos.welcome.welcomeMessage",
        list: "message",
        demo: true,
        selectAccount: true,
        startTrial: true
    },
    start: {
        title: "stratos.welcome.startTrial",
        message: "stratos.welcome.startTrialMessage",
        list: "message",
        continue: true,
        cancel: true,
        showAccount: true
    },
    terms: {
        title: "stratos.welcome.termsTitle",
        list: "terms",
        accept: true,
        cancel: true
    }
};

const WelcomePage = () => {
    const registerAction = useMixpanel("Welcome");
    const [trialProgress, setTrialProgress] = useState<TrialProgressStates>("welcome");
    const [userData] = useGlobalParam("userData");
    const [, currentAccount] = useAccounts(undefined, apiResources.accountUser);
    const subscribed = get(userData, "subscription.serial");
    const trialed = get(userData, "subscription.signUpComplete");
    const accountActive = subscribed || trialed;
    const classes = useStyles();
    const [translate] = useTranslation();
    const content = trialContents[trialProgress];
    const terms = useCrudProps<{content?: string}>(trialProgress === "terms" ? apiResources.softwareTerms : undefined)[0].data?.content;
    const [{error}, evalLoading, createEval] = useCrudFetch(CrudTypes.CREATE, apiResources.evaluation);
    const {push} = useHistory();

    let message: React.ReactNode;
    if (content.list === "message") {
        message = (
            <>
                {trialProgress === "welcome" ? (
                    <Typography variant="body2" className={classes.messageCenter}>
                        {translate("stratos.welcome.welcomeMessage2")}
                    </Typography>
                ) : null}
                {(accountActive || trialProgress === "start") ? (
                    <>
                        <Typography variant="body2" className={classes.messageCenter}>
                            {translate(
                                "stratos.subscription.currentAccount",
                                {context: subscribed ? "valid" : trialed ? "trial" : undefined, currentAccount}
                            )}
                        </Typography>
                        {trialProgress === "start" ? (
                            <InputLabel
                                label="stratos.welcome.dataResidency"
                                classes={{
                                    root: classes.inputRoot,
                                    inputHelp: classes.inputHelp,
                                    inputLabel: classes.inputLabel
                                }}
                            >
                                <Select
                                    id="residency"
                                    value="westeurope"
                                    choices={[{key: "westeurope", name: "stratos.welcome.westEurope"}]}
                                />
                            </InputLabel>
                        ) : null}
                    </>
                ) : null}
            </>
        );
    } else if (content.list === "terms" && terms) {
        message = (
            <Typography variant="body2" className={classes.terms} component="div">
                {terms.split("\n\n").map((line) => (<p key={line}>{line.replace("\n", " ")}</p>))}
            </Typography>
        );
    }

    return (
        <WholePageLayout
            title={content.title}
            message={content.message || undefined}
            list={message}
            demo={content.demo || undefined}
            selectAccount={content.selectAccount || undefined}
            onTrialClick={content.startTrial && !accountActive ? () => {
                setTrialProgress("start");
                registerAction("Create Trial Start");
            } : undefined}
            additionalButton={content.startTrial && accountActive ? (
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    href="/#/"
                >
                    {translate("stratos.welcome.home")}
                </Button>
            ) : (content.accept ? (
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={
                        () => createEval({data: {accountId: currentAccount.id, email: userData.currentUser}})
                            .then(({error}) => {
                                if (!error) {
                                    registerAction("Create Trial Success");
                                    push("/");
                                }
                            })
                    }
                    disabled={evalLoading}
                >
                    {evalLoading ? (<CircularProgress size={12}/>) : null}{translate("stratos.welcome.accept")}
                </Button>
            ) : undefined)}
            onCancel={content.cancel ? () => {
                setTrialProgress("welcome");
                registerAction("Create Trial Cancel");
            } : undefined}
            onContinue={content.continue ? () => {
                setTrialProgress("terms");
            } : undefined}
            errorMessage={trialProgress === "terms" && error?.message || undefined}
        />
    );
};

export default WelcomePage;