import React from "react";
import {
    StratosAuditIcon,
    StratosDashboardIcon,
    StratosEndpointIcon,
    StratosIntegrationsIcon,
    StratosNetworkIcon,
    StratosSecurityIcon
} from "@cuda-react/icons";

export const navigationDashboard = {
    exact: true,
    icon: <StratosDashboardIcon/>,
    path: "/",
    name: "stratos.navigation.dashboard"
};

export const navigationNetwork = {
    icon: <StratosNetworkIcon/>,
    path: "/infrastructure",
    name: "stratos.navigation.network.network",
    children: [
        {
            name: "stratos.navigation.network.gateways",
            path: "/infrastructure/gateways"
        },
        {
            name: "stratos.navigation.network.sites",
            path: "/infrastructure/sites"
        },
        {
            name: "stratos.navigation.network.iot",
            path: "/infrastructure/iot"
        },
        {
            name: "stratos.navigation.network.connectors",
            path: "/infrastructure/connectors"
        },
        {
            name: "stratos.navigation.network.vmActivation",
            path: "/infrastructure/vmactivation",
        },
        {
            name: "stratos.navigation.network.settings",
            path: "/infrastructure/settings",
        }
    ]
};

export const navigationTrinity = {
    icon: <StratosEndpointIcon/>,
    path: "/ztna",
    name: "stratos.navigation.ztna.ztna",
    children: [
        {
            name: "stratos.navigation.ztna.users",
            path: "/ztna/users"
        },
        {
            name: "stratos.navigation.ztna.devices",
            path: "/ztna/devices"
        },
        {
            name: "stratos.navigation.ztna.pointsOfEntry",
            path: "/ztna/pointsofentry"
        },
        {
            name: "stratos.navigation.ztna.appCatalog",
            path: "/ztna/appcatalog"
        },
        {
            name: "stratos.navigation.ztna.settings",
            path: "/ztna/settings"
        }
    ]
};

export const navigationSecurity = {
    icon: <StratosSecurityIcon/>,
    name: "stratos.navigation.securityPolicy.securityPolicy",
    path: "/policies",
    children: [
        {
            name: "stratos.navigation.securityPolicy.apps",
            path: "/policies/apps"
        },
        {
            name: "stratos.navigation.securityPolicy.network.network",
            children: [
                {
                    name: "stratos.navigation.securityPolicy.network.gateway",
                    path: "/policies/network/gateway"
                },
                {
                    name: "stratos.navigation.securityPolicy.network.site",
                    path: "/policies/network/site"
                },
                {
                    name: "stratos.navigation.securityPolicy.network.ingressNatRules",
                    path: "/policies/network/ingress"
                }
            ]
        },
        {
            name: "stratos.navigation.securityPolicy.sdwan.sdwan",
            children: [
                {
                    name: "stratos.navigation.securityPolicy.sdwan.applicationCategories",
                    path: "/policies/sdwan/categories"
                },
                {
                    name: "stratos.navigation.securityPolicy.sdwan.overrideCategories",
                    path: "/policies/sdwan/explicit"
                }
            ]
        },
        {
            name: "stratos.navigation.securityPolicy.webFilter.webFilter",
            children: [
                {
                    name: "stratos.navigation.securityPolicy.webFilter.urlFilter",
                    path: "/policies/webfilter/urlfilter"
                },
                {
                    name: "stratos.navigation.securityPolicy.webFilter.customCategories",
                    path: "/policies/webfilter/customcategories"
                },
                {
                    name: "stratos.navigation.securityPolicy.webFilter.webMonitorPolicies",
                    path: "/policies/webfilter/policies"
                },
                {
                    name: "stratos.navigation.securityPolicy.webFilter.settings",
                    path: "/policies/webfilter/settings"
                }
            ]
        },
        {
            name: "stratos.navigation.securityPolicy.security.security",
            children: [
                {
                    name: "stratos.navigation.securityPolicy.security.advThreatProtection",
                    path: "/policies/security/malware"
                },
                {
                    name: "stratos.navigation.securityPolicy.security.ssl",
                    path: "/policies/security/ssl"
                },
                {
                    name: "stratos.navigation.securityPolicy.security.ips",
                    path: "/policies/security/ips"
                }
            ]
        },
        {
            name: "stratos.navigation.securityPolicy.ztna.ztna",
            children: [{
                name: "stratos.navigation.securityPolicy.ztna.zeroTrustAccess",
                path: "/policies/ztna/zerotrustaccess"
            }]
        },
        {
            name: "stratos.navigation.securityPolicy.settings.settings",
            children: [
                {
                    name: "stratos.navigation.securityPolicy.settings.customResponse",
                    path: "/policies/settings/customresponse"
                }
            ]
        }
    ]
};
export const navigationAudit = {
    exact: true,
    icon: <StratosAuditIcon/>,
    name: "stratos.navigation.logs.logs",
    path: "/logs",
    children: [
        {
            name: "stratos.navigation.logs.audit",
            path: "/logs/audit",
        },
        {
            name: "stratos.navigation.logs.notifications",
            path: "/logs/notifications",
        },
        {
            name: "stratos.navigation.logs.syslog",
            path: "/logs/syslog"
        },
    ]
};
export const navigationIntegration = {
    icon: <StratosIntegrationsIcon/>,
    name: "stratos.navigation.integration.integration",
    path: "/integration",
    children: [
        {
            name: "stratos.navigation.integration.firewalls",
            path: "/integration/firewalls"
        },
        {
            name: "stratos.navigation.integration.settings",
            path: "/integration/settings",
        },
        {
            name: "stratos.navigation.integration.xdr",
            path: "/integration/xdr"
        },
        {
            name: "stratos.navigation.integration.ipsecV2",
            path: "/integration/ipsecV2"
        }
    ]
};

export const navigation = [
    navigationDashboard,
    navigationNetwork,
    navigationTrinity,
    navigationSecurity,
    navigationAudit,
    navigationIntegration
];

export const getNavigationRoutes = (): any[] => navigation;