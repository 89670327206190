import React, {ReactNode, useEffect, useState} from "react";
import {
    Account,
    useAccounts,
    useDeepCompareEffect,
    useGlobalParam,
    useSupportAccess,
    Workspace
} from "@cuda-react/core";
import apiResources from "../mainApiResources";
import {useTranslation} from "react-i18next";
import {TenantWorkspaceContext} from "../hooks/tenantWorkspaceHooks";
import {LogoutMenuContext} from "../hooks/logoutMenuHooks";

export interface TenantWorkspaceProviderProps {
    children: ReactNode;
}

const AppProvider = ({children}: TenantWorkspaceProviderProps): JSX.Element => {
    const [translate] = useTranslation();
    const noWorkspace = {name: translate("stratos.accounts.noWorkspace")};
    const [subscription = {}] = useGlobalParam("userData.subscription");
    const isSubscribed = subscription?.setUpComplete && subscription?.licensed && !subscription?.deprecated;
    const [accounts, currentAccount, defaultAccount, swapAccount, refreshAccounts] = useAccounts(
        isSubscribed ? apiResources.accountListWithVirtualWans : undefined,
        apiResources.accountUser,
        true
    );
    const availableAccounts = accounts.map((account) => ({
        ...account,
        virtualWans: account.virtualWans?.length ? account.virtualWans : []
    }));

    const [workspaceId, setWorkspaceId] = useGlobalParam("filter.virtualWanId");
    const [nextWorkspace, setNextWorkspace] = useState<number | string | null>(null);
    useEffect(() => {
        // wait until account has changed successfully before changing workspace
        nextWorkspace && setWorkspaceId(nextWorkspace);
    }, [currentAccount?.id]);
    const changeWorkspace = (account: Account, newWorkspaceId: string | number) => {
        if (account?.id !== currentAccount?.id) {
            swapAccount(account);
            setNextWorkspace(newWorkspaceId);
        } else {
            setWorkspaceId(newWorkspaceId);
        }
    };

    const [, setAccountTitle] = useGlobalParam("pageTitle.account");
    const workspaceChoices: Array<Workspace> = availableAccounts.find((account) => currentAccount.id === account.id)?.virtualWans ?? [];
    const workspace: Workspace = workspaceChoices.find((choice: Workspace) => choice.id === workspaceId) || noWorkspace;

    useDeepCompareEffect(() => {
        // if the workspaceId isn't one from the current account but the current account has workspaces, then update the id to one from the current account
        !workspace.id && workspaceChoices.length && setWorkspaceId(workspaceChoices[0].id);
    }, [workspace, workspaceChoices]);
    useEffect(() => {
        setAccountTitle(workspace?.id ? [currentAccount?.name, workspace.name] : currentAccount?.name);
    }, [currentAccount, workspace]);
    useEffect(() => {
        refreshAccounts();
    }, [isSubscribed]);

    const supportAccessAPI = isSubscribed && apiResources.supportAccessEnabled;
    const {supportAccessEnabled, onSupportAccessAccept} = useSupportAccess(supportAccessAPI || undefined);

    return (
        <TenantWorkspaceContext.Provider value={{
            availableAccounts,
            changeWorkspace,
            currentAccount,
            defaultAccount,
            refreshAccounts,
            swapAccount,
            workspaceChoices,
            workspace
        }}>
            <LogoutMenuContext.Provider value={{supportAccessEnabled, onSupportAccessAccept}}>
                {children}
            </LogoutMenuContext.Provider>
        </TenantWorkspaceContext.Provider>
    );
};

export default AppProvider;